<template>
  <div>
    <!-- footer -->
    <footer class="new-footer">
      <div class="new-footer-inner">
        <!-- footer cont area -->
        <section class="footer-content">
          <!-- top -->
          <div class="footer-cont top-area">
            <div class="cont-left cont-button">
              <button type="button" class="btn-top btn-scrollTop" @click="winTop">
                <i class="ico-arr">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 19V5"
                      stroke="#2F363D"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M19 12L12 5L5 12"
                      stroke="#2F363D"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </i>
                <span class="txt">Scroll to Top</span>
              </button>
            </div>
            <privacy :dialogVisible.sync="dialogVisible" />
            <div class="cont-right cont-article">
              <a href="#" class="link-privacy" @click.prevent="dialogVisible = true">
                <span class="txt">개인정보취급방침</span>
              </a>
            </div>
          </div>
          <!-- bottom -->
          <div class="footer-cont bottom-area">
            <div class="cont-left cont-social">
              <div class="tit">Let’s Connect With <span class="color2">Emotion Global</span></div>
              <ul class="list">
                <!-- 버튼 추가 -->
                <li>
                  <a href="https://www.behance.net/emotionglobal" target="_blank" title="새창열림">
                    <span class="txt">Behance</span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/emotion.co.kr/"
                    target="_blank"
                    title="새창열림"
                  >
                    <span class="txt">Facebook</span>
                  </a>
                </li>
                <li>
                  <router-link to="/careers" @click.native="$emit('update:navActive', false)">
                    <span class="txt">Careers</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/about" @click.native="$emit('update:navActive', false)">
                    <span class="txt">About</span>
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="cont-right cont-info">
              <ul>
                <li class="info-address">
                  <div>5F, 637, Eonju-ro, Gangnam-gu,<br />Seoul, Republic of Korea</div>
                  <div>T 02.542.1987 F 02.542.1988</div>
                </li>
                <li class="info-email">
                  <a href="mailto:inquiry@emotion.co.kr">inquiry@emotion.co.kr</a>
                </li>
                <li class="info-copyright">ⓒ e · motion Global Inc. All rights reserved.</li>
              </ul>
            </div>
          </div>
        </section>

        <!-- animation area -->
        <section class="footer-animation">
          <article class="svg">
            <div class="footer-copy">
              <span class="_char">M</span>
              <span class="_char">a</span>
              <span class="_char">k</span>
              <span class="_char">e</span>
              <span class="_char">&nbsp;</span>
              <span class="_char">G</span>
              <span class="_char">r</span>
              <span class="_char">e</span>
              <span class="_char">a</span>
              <span class="_char">t</span>
              <span class="_char">n</span>
              <span class="_char">e</span>
              <span class="_char">s</span>
              <span class="_char">s</span>
            </div>
          </article>
        </section>
      </div>
    </footer>
  </div>
</template>
<script>
  import privacy from "@/views/privacy";
  import bus from "@/utils/bus";
  export default {
    name: "AppFooter",
    data() {
      return {
        dialogVisible: false
      };
    },
    components: {
      privacy
    },
    props: ["smoothScrollBar"],
    methods: {
      winTop() {
        bus.$emit("winScrollTo", 0, 0);
      }
    }
  };
</script>
<style lang="scss" scoped>
  .new-footer {
    background-color: #eeeeee;
    overflow: hidden;

    .new-footer-inner {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      max-width: 1920px;
      margin: 0 auto;
      font-family: "Montserrat", sans-serif;
    }

    // 컨텐츠 영역역
    .footer-content {
      padding: 50px 10vw 80px;

      .footer-cont {
        display: flex;
        justify-content: space-between;

        &.top-area {
          align-items: center;
          margin-bottom: 120px;
        }
        &.bottom-area {
          align-items: flex-end;
        }
      }

      .btn-top {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 12px;
        background-color: #eeeeee;
        .ico-arr {
          display: block;
          width: 24px;
          height: 24px;
          transform: translate3d(0, 0, 0);
          transition: 0.2s ease-in;
          svg path {
            stroke: #000;
          }
        }
        .txt {
          font-size: 16px;
          font-weight: 500;
        }

        // hover
        &:hover {
          animation: yoyoUp 0.3s infinite alternate;
          .ico-arr {
            animation: yoyoUp 0.3s infinite alternate;
            svg path {
              stroke: $red;
            }
          }
          .txt {
            color: $red;
          }
        }
      }

      .link-privacy {
        position: relative;
        display: flex;
        align-items: center;

        .txt {
          color: #000;
          font-family: "Montserrat", sans-serif;
          font-size: 16px;
          font-weight: 500;
          transition: 0.2s ease-in;
        }

        // hover
        &:hover {
          .ico-arr {
            transform: translate3d(0, -3px, 0);
            svg path {
              stroke: $red;
            }
          }
          .txt {
            color: $red;
          }
        }
      }

      // 소셜링크
      .cont-social {
        .tit {
          position: relative;
          font-size: 24px;
          font-weight: 600; // semiBold
          letter-spacing: -0.01em;
          .color2 {
            color: $red;
          }
          &::after {
            content: "";
            position: absolute;
            top: -24px;
            left: 0;
            display: block;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background-color: $red;
          }
        }
        .list {
          display: flex;
          flex-direction: column;
          row-gap: 14px;
          margin-top: 40px;

          a {
            position: relative;
            display: inline-block;

            .txt {
              display: inline-flex;
              color: #000;
              font-size: 20px;
              font-weight: 500;
              transform: translateX(0);
              transition: 0.2s ease-in;
            }

            &:hover {
              .txt {
                transform: translateX(10px);
                color: $red;
              }
            }
          }
        }
      }

      // 회사정보
      .cont-info {
        text-align: right;
        ul {
          display: flex;
          flex-direction: column;
          row-gap: 16px;
        }
        .info-address {
          font-size: 16px;
          line-height: 1.5;
          div + div {
            margin-top: 8px;
          }
        }
        .info-email a {
          color: #555;
          font-size: 16px;
          &:hover {
            color: $red;
          }
        }
        .info-copyright {
          font-size: 13px;
        }
      }
    }

    //애니메이션
    .footer-animation {
      padding: 0 120px;
      padding-bottom: 50px;
      overflow: hidden;
      border-bottom: 1px solid #eee;

      article.svg {
        position: relative;
        user-select: none;
        cursor: auto;

        .footer-copy {
          position: relative;
          display: flex;
          justify-content: center;
          width: 100%;
          letter-spacing: -0.28em;
          line-height: 1;
          white-space: nowrap;
          transform-origin: center;
          perspective: 800px;
          transform-style: preserve-3d;

          ._char {
            position: relative;
            display: block;
            color: $red;
            font-family: "Montserrat", sans-serif;
            font-size: clamp(50px, 8.7vw, 156px);
            font-weight: 700;
            line-height: 1;
            text-transform: uppercase;
            transform-origin: center;
            min-width: 2.1rem;
          }
        }
      }
    }
  }

  // 플로팅 폰트
  .main-floating-section-link,
  .floating-menu {
    font-family: "Montserrat", sans-serif;
  }
  // 플로팅 섹션 링크
  .main-floating-section-link {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 10;
    // z-index: 999;

    .main-floating-link {
      position: absolute;
      opacity: 0;
      // visibility: hidden;

      .floating-btn {
        position: absolute;
        left: 50%;
        bottom: -60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 20px;
        height: 76px;
        padding: 0 10px 0 10px;
        border-radius: 38px;
        background: rgba(238, 238, 238, 0.8);
        box-shadow: 0 5px 40px rgba(0, 0, 0, 0.2);
        transform: translateX(-50%);
        backdrop-filter: blur(3px);

        > span {
          display: flex;
          transition: 0.3s ease-in-out;
        }

        .btn-ico {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 56px;
          height: 56px;
          border-radius: 50%;
          background-color: #fff;
          color: #fff;
          &::after {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 34px;
            height: 34px;
            background: url("/assets/images/main/ico-e-red.svg") no-repeat center/contain;
            transform: translate(-50%, -50%) scale(1);
            transition: 0.3s;
          }

          &.ico-work::after {
            background-image: url(../assets/images/main/ico-work.svg);
          }

          &.ico-magazine::after {
            background-image: url(../assets/images/main/ico-magazine.svg);
          }
        }
        .btn-txt {
          color: #000;
          font-size: 16px;
          font-weight: 500;
          white-space: nowrap;
        }
        .btn-arr {
          position: relative;
          width: 24px;
          height: 24px;
          // background: url('/assets/images/main/ico-arrow-up.svg') no-repeat;
          transform: translate3d(0, 0, 0);
        }

        // 버튼 hover
        &:hover {
          .btn-ico {
            &::after {
              // transform: translate(-50%, -50%);
              animation: yoyoScale 0.3s alternate;
              animation-iteration-count: 4;
            }
          }
          .btn-txt {
            color: $red;
          }
          .btn-arr {
            transform: rotate(45deg);

            svg.arr-svg path {
              stroke: $red;
            }
          }
        }
      }

      // &.active {
      //   // border: 1px solid red;
      //   // opacity: 1;
      //   // visibility: visible;
      // }
    }
  }

  @keyframes yoyoUp {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(0, -3px, 0);
    }
  }

  @keyframes yoyoDown {
    0% {
      transform: translate3d(0, 5px, 0) rotate(180deg);
    }
    100% {
      transform: translate3d(0, -5px, 0) rotate(180deg);
    }
  }

  @keyframes yoyoScale {
    0% {
      transform: translate3d(-50%, -50%, 0) scale(1);
    }
    100% {
      transform: translate3d(-50%, -50%, 0) scale(1.2);
    }
  }
</style>
